<template>
	<div class="el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }">
		    <a-form-item label="服务名称" name="name">
		    	<a-input placeholder="服务名称" v-model:value="form.name"/>
		    </a-form-item>
		    <a-form-item label="服务说明" name="summary">
		    	<a-input placeholder="服务说明" v-model:value="form.summary"/>
		    </a-form-item>
		    <a-form-item label="服务价格" name="price">
		    	<a-input placeholder="服务价格" addon-after="元" v-model:value="form.price"/>
		    </a-form-item>
		    <a-form-item label="托管类型">
		        <a-radio-group v-model:value="form.ser_type">
		    		<a-radio :value="1">包种植+包配送 </a-radio>
		    	    <a-radio :value="2">包种植</a-radio>
		    	    <a-radio :value="3">仅出租土地，不含其它服务</a-radio>
		        </a-radio-group>
		    </a-form-item>
		    <a-form-item label="服务特色" v-for="(item,index) in form.ser_json" :key="index">
		    	<a-space style="display: flex;cursor: pointer;align-items: flex-start;">
		    		<kd-img-select :mutil="false" :src="item.cover" @change="(url)=>{ item.cover = url }"></kd-img-select>
		    		<a-input v-model:value="item.name" placeholder="名称" style="width: 300px;"></a-input>
		    		<a-input v-model:value="item.summary" placeholder="补充说明" style="width: 300px;"></a-input>
		    		<i class="ri-delete-bin-5-line ri-lg" @click="addSer(2,index)"></i>
		    	</a-space>
		    </a-form-item>
		    <a-form-item :wrapper-col="{ offset: 2 }">
		    	<div class="lsefi-add" @click="addSer(1)"><i class="ri-add-line"></i>添加特色</div>
		    </a-form-item>
		    <a-form-item :wrapper-col="{offset: 2 }">
		    	<a-space>
		    		<a-button type="primary" @click="saveSubmit">保存</a-button>
		    		<a-button @click="$router.go(-1)">返回</a-button>
		    	</a-space>
		    </a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import landModel from '@/api/land.js'
export default{
	name:'land-service-edit',
	props:{
		isShop:{
			type:Number
		}
	},
	setup(){
		const _d = reactive({
			form:{
				id:0,
				name:'',
				ser_type:1,
				summary:'',
				price:'',
				ser_json:[
					{cover:'',summary:'',name:''}
				]
			},
		})
		const opt = useRoute().query
		if( opt.id ) landModel.getLandServerDetail(opt.id,Object.keys(_d.form),res=>_d.form = res)

		const saveSubmit = () => landModel.addOrEditLandServer(_d.form)
		const addSer = (e,i=0) => e==1 ? _d.form.ser_json.push({cover:'',summary:'',name:''}) : _d.form.ser_json.splice(i,1)
		
		return {
			...toRefs(_d),
			saveSubmit,
			addSer
		}
	},
}
</script>

<style lang="scss">
	.lsefi-add{
		width: 790px;
		border: 1px dashed #d1d1d1;
		height: 34px;
		line-height: 34px;
		text-align: center;
		color: #999;
		margin-bottom: 24px;
		cursor: pointer;
	}
</style>
